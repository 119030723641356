/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import "~swiper/swiper-bundle.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* styles.css */
@import '~ngx-print-element/styles.css';


h1,
h2,
h3,
h4 {
    font-family: 'poppins-bold';
    margin-top: 0;
    margin-bottom: 0;
}


h5,
h6 {
    font-family: 'poppins-bold';
    margin-top: 0;
    margin-bottom: 0;
}

body {
    color: black;
    font-family: 'poppins';
    font-size: var(--ion-font-body);
}

* {

    -webkit-user-select: text;

    -moz-user-select: text;

    -ms-user-select: text;

    user-select: text;

}

ion-header {
    background-color: white !important;
}

ion-content {
    --background: #f5f5ff;
    // url(./assets/images/bg2.png) no-repeat top center fixed !important;
}

#logo {
    width: 100%;
    max-width: 250px;
    min-width: 100px;
    cursor: pointer;
    position: absolute;
    z-index: 100;
    top: 0;
}

.page-size-header {
    max-width: 1400px;
    margin: 0 auto;
}

ion-header.plain-header {
    ion-menu-button {
        color: white;
    }

    ion-toolbar,
    ion-tab-bar {
        // --background: #51372a;
        --background: linear-gradient(to right, rgb(255, 136, 0), white);
    }

    ion-icon {
        font-size: var(--ion-font-bod-bigger);
        color: var(--ion-color-secondary-contrast);
    }

    .tab-selected {
        ion-icon {
            color: var(--ion-color-secondary);
        }
    }

    ion-label {
        font-size: var(--ion-font-body);
        color: var(--ion-color-secondary-contrast);
    }

    .meals-logo {
        max-width: 90%;
        margin: 0;
    }

    ion-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .munchcity-logo {
        width: 120px;
        margin: 0px;
    }

    ion-header {
        margin-bottom: 50px;
    }
}

.button-circle {
    color: rgb(0, 0, 0);
    // border: 1px solid white;
    border-radius: 30px;
    width: 80%;
    padding: 10px 0px;
    display: inline-block;
    text-transform: uppercase;
    font-size: var(--ion-font-body-big);
    letter-spacing: 1px;
    text-align: center;

    &.button-gradient {
        // background-color:#e39e50;
        background-image: linear-gradient(to right, #80c82c, #3960af);
        border-color: #80c82c;
    }

    &.light-grey {
        background-color: rgb(211, 211, 211);
    }

    &.full-width {
        width: 100%;
    }
}

.item-native {
    --padding-start: 0px;
    --ion-safe-area-left: 0px;
}

.text-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.hash-gradient {
    // background-color:#e39e50;
    background-image: linear-gradient(to right, #80c82c, #3960af);
}

.order-request {
    border: 1px solid rgb(194, 194, 194);
    border-radius: 10px;
    margin: 0.15rem 1rem;
    background-color: white;

    p {
        margin: 0;
    }

    .title {
        font-size: var(--ion-font-body);
        color: rgb(70, 70, 70);
    }

    .value {
        font-size: var(--ion-font-body-small);
    }

    .left-image {
        display: flex;
        justify-content: center;
        flex-direction: column;

        img {
            width: 50% !important;
        }
    }
}

.invalid-button {
    background-image: none !important;
    background-color: rgb(211, 211, 211) !important;
}

.valid-button {
    background-image: linear-gradient(to left, #80c82c, #3960af);
}

.spinner-row {
    width: 100%;
    height: calc(100vh - 130px);

    ion-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        ion-spinner {
            margin: 0 auto;
        }
    }
}

.margin-top-0 {
    margin-top: 0;
}

.margin-top-04 {
    margin-top: .4rem;
}

.margin-top-05 {
    margin-top: .5rem;
}

.margin-top-06 {
    margin-top: .6rem;
}

.margin-top-07 {
    margin-top: .7rem;
}


.margin-top-1 {
    margin-top: 1rem !important;
}

.margin-top-1-2 {
    margin-top: 1.2rem !important;
}


.margin-top-2 {
    margin-top: 2rem !important;
}

.margin-top-3 {
    margin-top: 3rem !important;
}

.margin-top-4 {
    margin-top: 4rem !important;
}

.margin-top-6 {
    margin-top: 6rem !important;
}

.margin-top-5 {
    margin-top: 5rem !important;
}


.margin-top-8 {
    margin-top: 8rem !important;
}

.margin-top-10 {
    margin-top: 10rem;
}

.margin-bot-1 {
    margin-bottom: .5rem;
}

.margin-bot-2 {
    margin-bottom: 2rem;
}

.margin-bot-3 {
    margin-bottom: 3rem;
}

.margin-bot-4 {
    margin-bottom: 4rem;
}

.margin-bot-10 {
    margin-bottom: 10rem;
}

.margin-bot-30 {
    margin-bottom: 30rem;
}

.padding-1 {
    padding: 1em
}

.padding-left {
    padding-left: 1.5em
}

.padding-2 {
    padding: 2em
}

.margin-left-0-5 {
    margin-left: .5rem;
}

.margin-right-1 {
    margin-right: 1rem;
}

.margin-right-0-5 {
    margin-right: .5rem;
}

.margin-left-1 {
    margin-left: 1rem;
}

.margin-left-2 {
    margin-left: 2rem;
}

.margin-left-4 {
    margin-left: 4rem;
}

.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row-center-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.flex-space {
    display: flex;
    justify-content: space-between;
}

.flex-around {
    display: flex;
    justify-content: space-around;
}


.flex-center-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}


.flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.flex-wrap-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.flex-top {
    display: flex;
    align-items: flex-start;
}

.flex-topcenter {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-btw {
    display: flex;
    justify-content: space-between;
    margin: .3rem 1rem;
    align-items: center;

    img {
        max-width: 180px;
    }
}



.flex-rowstart {
    display: flex;
    align-items: center;
    flex-direction: row;
}


.flex-rowcenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.flex-rowbtw {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-rowbtw-start {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.flex-rowend {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}


.flex-colcenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flex-colend {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column !important;
}

.flex-colstart {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column !important;
}


.flex-right {
    position: absolute;
    height: 75%;
    right: 0;
}

.a-link-3 {
    color: black;
    margin: 0 .4rem;
    cursor: pointer;
}


.vert-center {
    display: flex;
    align-items: center;
}


.center {
    text-align: center !important;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.border-0 {
    border: 1px solid rgb(231, 231, 231);
    border-radius: 6px;
    padding: .2rem;
}

.border-1 {
    border: 1px solid #dddddd;
    border-radius: 6px;
}

.border-red {
    border: 1px solid red;
}

.border {
    border: 1px solid rgb(201, 201, 201);
    border-radius: 6px;
    padding: .4rem;
}

.border-5 {
    border: 5px solid rgb(29, 29, 29);
    border-radius: 6px;
    padding: .4rem;
}

.flex-dir-col {
    flex-direction: column;
}

.flex-space-btw {
    justify-content: space-between;
}

.border-radius {
    border-radius: 10px;
}

.border-bot {
    border-bottom: 1px solid rgb(212, 212, 212);
}

.text-rotate {
    ion-col {
        transform: rotate(45deg);
    }
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ellip-small {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--ion-font-body-small);
}

.border-bottom {
    border-bottom: 1px solid rgb(212, 212, 212);
    padding-bottom: .5rem;
}

.border-top {
    border-top: 1px solid rgb(212, 212, 212);
    padding-top: .7rem;
}


.border-dashed {
    border: dashed rgb(255, 166, 0) 1px;
}

.bg-red-dark .bord {
    border: 1px solid rgb(117, 117, 117);
    margin: 1rem;
    border-radius: 6px;
}

.bord-light {
    border: 1px solid #cbcbcb;
    margin: 1rem .25rem;
    border-radius: 6px;
}

.bord-ti {
    border: 1px solid #c0c0c0;
    border-radius: 3px;
    padding: 0.2rem;
    margin: 0 auto;
}

.bg-gray {
    background-color: rgb(243, 243, 243);
}

.bg-red {
    background-color: red
}

.bg-red-dark {
    background-color: #57362c;
}

.bg-white {
    background-color: white !important;
}

.bg-white-light {
    background-color: rgba(255, 255, 255, 0.116);
}

.bg-orange-light {
    background-color: var(--ion-orange);
}

.bg-grey {
    background-color: #efe9e9 !important;
}

.bg-black-light {
    background-color: rgba(0, 0, 0, 0.24);
}

.orange-dark {
    color: var(--ion-orange-dark) !important;
}


.orange {
    color: var(--ion-orange) !important;
}

.orange-light {
    color: var(--ion-orange);
}



.blue {
    color: rgb(0, 52, 163) !important;
}

.my-blue {
    color: #3A86F9;
}

.red {
    color: var(--ion-red)
}


.black {
    color: rgb(0, 0, 0);
}

.black-bg {
    background-color: rgb(0, 0, 0);
}

.green {
    color: green
}

.brown-bg {
    background-color: var(--ion-orange-dark);
}

.white-bg {
    background-color: #ffffff;
}

.border-square {
    border: 1px solid rgb(168, 168, 168);
    border-radius: 3px;
}

.b-1 {
    ion-col {
        border: 1px solid rgb(255, 0, 0) !important;
    }
}

.orangex {
    color: rgb(172, 70, 2) !important;
}

.custom-btn {
    background-color: var(--ion-orange);
    border-radius: 7px;
    font-size: var(--ion-font-body-small);
    color: black;
    padding: .5rem 1rem;
}

.custom-btn-2 {
    background-color: var(--ion-orange);
    border-radius: 7px;
    font-size: var(--ion-font-body);
    color: black;
    padding: 1rem;
    font-weight: 700;
    width: 50%;
    margin: .2rem auto !important;
}

.custom-btn-3 {
    background-color: rgb(214, 214, 214);
    border-radius: 7px;
    font-size: var(--ion-font-body);
    color: black;
    padding: 1rem;
    font-weight: 700;
    width: 50%;
    margin: .2rem auto !important;
}

.custom-btn-order {
    background-color: #f0ffbd;
    border-radius: 4px;
    font-size: var(--ion-font-body);
    border: 1px solid var(--ion-green);
    color: #2e682e;
    padding: 0.2rem 0.3rem;
}

.padding {
    padding-top: .1rem;
    padding-bottom: 3rem;
}

.note {
    border: 1px sollid rgb(204, 204, 204);
    background-color: rgb(255, 249, 242);
    border: 1px solid gray;
    border-radius: 10px;
    height: 100px;
}

.ion-row {
    border-bottom: 1px dotted gray;
}

.input-container {
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    background-color: #fcf6de;
    border: 1px solid #938f8f;
    margin: .5rem 1rem;
}

ion-input,
.input {
    padding: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --inner-padding-end: 0;
    font-weight: 100;
    --background: white !important;
    --background-color: white !important;
    background: white !important;
    background-color: white !important;
    font-family: 'poppins' !important;
}

.main-div-0 {
    width: 98%;
    max-width: 1024px;
    background-color: rgb(238, 236, 236);
    border-radius: 15px;
    padding: .4rem;
    margin: 1rem auto;
}

.cursor {
    cursor: pointer;
}

.smaller-font {
    font-size: var(--ion-font-body-smaller) !important;
    text-transform: capitalize !important;
}

.small-font {
    font-size: var(--ion-font-body-smal) !important;
    text-transform: capitalize !important;
}

.medium-font {
    font-size: var(--ion-font-body);
    color: rgb(0, 56, 160);
}

.big-font-green {
    font-size: var(--ion-font-body);
    color: var(--ion-green)
}

.small-font-green {
    font-size: var(--ion-font-body-small);
    color: var(--ion-green)
}

.big-font-blue {
    font-size: var(--ion-font-body);
    color: #4e9dff
}

.small-font-blue {
    font-size: var(--ion-font-body-small);
    color: #4e9dff
}

.big-font {
    font-size: var(--ion-font-body-bigger);
    font-weight: 700;
    color: var(--ion-orange-dark)
}

.delivery {
    background-color: rgb(49, 59, 65);
    color: rgb(255, 255, 255);
    font-size: var(--ion-font-body);
    padding: .2rem .4rem;
    border-radius: 5px;
}

.urgent {
    background-color: rgb(122 122 122);
    color: rgb(255, 255, 255);
    font-size: var(--ion-font-body-small);
    padding: .2rem .4rem;
}

.urgent-2 {
    background-color: rgb(169 169 169);
    color: rgb(255, 255, 255);
    font-size: var(--ion-font-body);
    padding: .2rem .4rem;
    height: 40px;
}

.urgent-3 {
    background-color: #3a3a3a;
    color: white;
    font-size: var(--ion-font-body);
    padding: .25rem;
    min-height: 30px;
}

.due-nbr {
    background-color: black;
    color: white;
    font-size: var(--ion-font-body);
    border-radius: 50%;
    padding: .1rem 0.5rem;
    margin-bottom: 0.4rem;
    border: 3px solid var(--ion-orange);
}

.due-nbr-2 {
    background-color: rgb(34, 33, 33);
    color: white;
    font-size: var(--ion-font-body);
    border-radius: 5px;
    padding: .3rem;
    max-width: 90%;
    margin: .2rem auto 0 auto
}

.opacity-1 {
    display: none;
}

.opacity-50 {
    display: .5;
}

.opacity-100 {
    display: 1;
}

.opacity-2 {
    opacity: 1;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 10px;
    background-color: rgb(253, 249, 215);
    margin: .2rem 0
}

//--- modal div
.modalDialog {
    position: fixed;
    font-family: Arial, Helvetica, sans-serif;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    opacity: 1;
    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
}

.modalDialog:target {
    opacity: 1;
    pointer-events: auto;
}

.modalDialog>div {
    width: 330px;
    position: relative;
    margin: 30% auto;
    padding: 5px 20px 13px 20px;
    border-radius: 10px;
    background: #f3f3f3;
}

.close {
    background: var(--ion-orange-dark);
    color: #FFFFFF;
    line-height: 25px;
    position: absolute;
    right: -12px;
    text-align: center;
    top: -10px;
    width: 24px;
    text-decoration: none;
    font-weight: bold;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    -moz-box-shadow: 1px 1px 3px #000;
    -webkit-box-shadow: 1px 1px 3px #000;
    box-shadow: 1px 1px 3px #000;
}

.close:hover {
    background: #00d9ff;
}

.cal-div {
    text-align: center;
    // overflow: auto;
    height: 400px;
    padding: 1rem;

    >div {
        width: 100px;
        height: 100px;
        display: inline-block;
        border: 1px solid rgb(255, 254, 254);
        border-radius: 5px;
        background-color: white;
        font-size: var(--ion-font-body-small) !important;
        margin: .6rem;
        padding: .3rem;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgb(158, 158, 158);
    }
}

//----------------------------------vendor

.curve {
    border-radius: 7px;
    margin: .4rem
}

.top-curve {
    position: absolute;
    top: 0;
    z-index: 100;
}

.radius {
    border-radius: 6px;
}

.rating {
    display: flex;
    margin-top: 0.5rem;
    flex: 1;

    .filled {
        color: var(--ion-orange);
    }

    .empty {
        color: gray;
    }
}

.filled {
    color: var(--ion-orange);
}

.rating2 {
    margin-top: 0.5rem;
    flex: 1;

    .filled {
        color: var(--ion-orange);
    }

    .empty {
        color: gray;
    }
}

.ratings {
    display: flex;

    .filled {
        color: var(--ion-orange);
        font-size: var(--ion-font-body);
    }

    .empty {
        color: gray;
        font-size: var(--ion-font-body);
    }
}


.div1 {
    width: 100%;
    border-bottom: 1px dotted rgb(95, 95, 95);

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}

.div1 {
    width: 100%;

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}

.about-chef {
    width: 100%;

    img {
        width: 100%;
        object-fit: cover;
    }

    ion-icon {
        color: var(--ion-orange) !important;
        margin-right: .18rem !important;
    }
}

.about-chef {
    ion-icon {
        color: grey;
        margin-right: 1rem;
    }
}

.rating-1 {
    font-size: var(--ion-font-body);
    color: var(--ion-orange);
    border-radius: 50%;
    padding: .1rem;
    margin: 0 .081rem;
}

.rating-2 {
    font-size: var(--ion-font-body);
    color: rgb(165, 165, 165);
    border-radius: 50%;
    padding: .1rem;
    margin: 0 .081rem;
}

.rating-01 {
    font-size: var(--ion-font-body);
    color: var(--ion-orange);
    border-radius: 50%;
    margin: 0 .081rem;
}

.rating-02 {
    font-size: var(--ion-font-body);
    color: #817e79;
    border-radius: 50%;
    margin: 0 .081rem;
}


.highlight {
    color: var(--ion-red);
    font-weight: 600;
}

.weight {
    font-weight: 600;
}

.section-meal {
    background-color: #848484;
    color: white;
    padding: 1.5rem;
    margin: .1rem;
}

.review {
    border-bottom: 1px solid rgb(196, 196, 196);
    margin-top: 1.5rem;
    padding: .6rem;
}

.ion-segment {
    border-radius: 7px;
    padding: 0 0 .4rem 0;
}

.img-cover {
    height: 100px;
    border-radius: 50%;
}

.invalid {
    // background-color: rgb(187, 187, 187);
    opacity: .5;
    //  cursor: not-allowed !important;
}

.icon-but {
    font-size: var(--ion-font-body-bigger);
    font-weight: bolder;
    color: white;
    margin-left: auto;
    margin-right: auto;
}

.icon {
    font-size: var(--ion-font-body-big) !important;
    font-weight: bolder;
    color: var(--ion-col1) !important;
    margin-left: auto;
    margin-right: auto;
}

.icon-big {
    font-size: var(--ion-font-body-bigger) !important;
    font-weight: bolder;
    margin-left: auto;
    margin-right: auto;
}

.icon-reg {
    font-size: var(--ion-font-body-big) !important;
    font-weight: bolder;
}

.img-cover-70 {
    height: 70px;
    border-radius: 50%;
    margin: .4rem;
}

.img-cover-50 {
    height: 50px;
    border-radius: 5px;
    margin: .2rem;
}

.img-cover-square {
    object-fit: cover;
    border-radius: 10px;
    object-fit: cover;
    height: 100%;
}

.img-cover-2 {
    position: relative;

    ion-col {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;

        img {
            object-fit: cover;
            width: 80px;
            opacity: .9;
        }

        input {
            position: relative;
            bottom: 0;
            width: 100%;
            font-size: var(--ion-font-body-small);
            border: 0;
            color: #ff5200;
            margin-left: .2rem;
            width: 130px;
            border-radius: 5px;
            height: 40px;
        }
    }
}

.grey-back {
    background-color: rgb(233, 233, 233);
}

.grey {
    color: rgb(153, 153, 153) !important;
}

.grey-dark {
    color: rgb(133, 133, 133)
}

.white {
    color: white !important;
}

.div-form-wide {
    padding: 0.21rem;
    border: 1px solid gray;
    border-radius: 6px;
    margin: 1rem;
    background-color: white;
    width: 100%;
    max-width: 800px;
    height: 750px;
    overflow: auto;

}


.small-but {
    color: rgb(0, 0, 0);
    padding: 0.2rem !important;

    ion-icon {
        font-size: var(--ion-font-body-bigger);
        color: rgb(104, 104, 104);
    }

    span {
        color: rgb(0, 0, 0);
        font-size: var(--ion-font-body-small);
        text-transform: capitalize;
        font-weight: 700;

    }
}

.small-but-3 {
    color: rgb(0, 0, 0);
    padding: 0.2rem !important;
    height: 70px;
    margin: 2rem auto;

    ion-icon {
        font-size: var(--ion-font-body-big);
        color: rgb(104, 104, 104);
    }

    span {
        color: rgb(0, 0, 0);
        font-size: var(--ion-font-body-small);
        text-transform: capitalize;
        font-weight: 700;

    }
}

.cap {
    text-transform: capitalize !important;
}

.uppercase {
    text-transform: uppercase;
}

.small-but-2 {
    color: rgb(0, 0, 0);
    padding: 0.2rem !important;
    border: 1px solid rgb(175, 175, 175);
    background-color: rgb(238, 238, 238);
    border-radius: 5px;
    margin: 0 .5rem;
    padding: .1rem;

    ion-icon {
        font-size: var(--ion-font-body);
        color: rgb(0, 83, 131);
    }

    span {
        color: rgb(0, 83, 131);
        font-size: var(--ion-font-body-smaller);
        text-transform: capitalize;
    }
}

#uploadBtn {
    font-size: var(--ion-font-body-small);
    background-color: var(--ion-orange);
    color: black;
}

.premium {
    background-color: black;
    color: var(--ion-orange);
    padding: 0.15rem .25rem;
    width: 130px;
    font-size: var(--ion-font-body-small);
    border-radius: 5px;
    border: 1px solid white;
    text-align: center;
}

.premium-2 {
    background-color: rgb(53, 53, 53);
    color: rgb(255, 255, 255);
    padding: 0.15rem .25rem;
    width: 130px;
    font-size: var(--ion-font-body-small);
    border-radius: 5px;
    border: 1px solid white;
    text-align: center;
}

.prem-cal {
    color: var(--ion-red);
    background-color: rgb(0, 0, 0);
    padding: 1rem;
    width: 80%;
    margin: 1rem auto;
    border-radius: 8px;
}

.prices {
    background-color: #eee;
    padding: 1rem;
    border: 1px solid gray;
    margin: 2rem auto;
    max-width: 500px;
    border-radius: 8px;
}

.price {
    font-size: var(--ion-font-body);
}

.ii {
    width: 96%;
    max-width: 650px;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 7px;
    margin: 1rem auto;
}


.strong {
    font-weight: 700;
}

.nutrition {
    text-align: center;
    overflow: hidden;
    max-width: 700px;

    .form-group {
        width: 130px;
        border: 1px dotted grey;
        border-radius: 7px;
        display: inline-block;
        margin: .6rem;
        box-sizing: border-box;
        padding: .4rem;
        background-color: #fffacb;
        font-size: var(--ion-font-body);
        text-align: center;
    }
}

.input-and-icon {
    position: relative;

    fa-icon {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translate(0%, -50%);
        color: red;
        display: none;
        cursor: pointer;
    }

    &:hover {
        fa-icon {
            display: block;
        }
    }
}

.tag-button {
    min-width: 130px;
    border: 1px dotted grey;
    border-radius: 10px;
    color: blue;
    padding: .4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: .15rem;
    background-color: white;

    ion-icon {
        font-size: var(--ion-font-body-big);
        margin-left: 1rem;
    }
}

.box {
    height: 50px;
    margin: 0 auto;
}

.earn {
    margin: .3rem
}



.text-shadow-white {
    text-shadow: 2px 2px 4px #ffffff;
}

.text-shadow-black {
    text-shadow: 2px 2px 4px #000000;
}

#q-btn {
    text-align: right;

    .c-btn {
        background-color: var(--ion-orange);
        border-radius: 5px;
        font-size: var(--ion-font-body-small);
        color: rgb(255, 255, 255);
        margin: .3rem;
        padding: .3rem .5rem;
        text-shadow: 1px 1px 2px #341700;

        &.invalid-btn {
            opacity: 0.4;
        }

        &.medium-btn {
            width: 200px;
            margin-top: 20px;
            background-color: #ffebcc;
        }
    }
}

.double-arrow {
    width: 25px;
    background-color: white;
    border-radius: 40%;

    img {
        width: 100%;
    }
}

.show-instr {
    height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1rem;
    background-color: #fafafa;
    font-size: var(--ion-font-body);
    border: 1px solid rgb(194, 194, 194);
    border-radius: 7px;
}

ion-grid {
    ion-row {
        // background-color: #f3f3f3;
    }
}

.vendor-details {
    background-color: rgb(240, 240, 240);
}

.chart-row {
    background-color: #252728;
    color: #c4c4c4;
    border-bottom: 1px dotted #727272;
    min-height: 40px;
    display: flex;
    align-items: center;
}

.chart-div {
    background-color: #373c40;
    color: #d1d1d1;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    font-size: var(--ion-font-body);
    width: 98%;
}

.alert-wrapper.sc-ion-alert-md,
.sc-ion-alert-md-h {
    --min-width: 360px !important;
    --min-width: 360px !important;
}

.profile-loading {
    ion-spinner {
        margin: 0 auto;
        color: var(--ion-orange);
    }
}

.marg-center {
    margin-right: auto;
    margin-left: auto;
}

.width-750 {
    padding: 1rem;
    max-width: 750px;
}

.width-100 {
    width: 100%
}

.width-90 {
    width: 90%
}

.width-300 {
    width: 300px
}

.bold {
    font-weight: 700;
}

.bold-600 {
    font-weight: 600;
}

.bold-500 {
    font-weight: 500;
}

.div-logo {
    background-image: linear-gradient(transparent, white);
    border-radius: 30%;
    padding: 3px;
    width: 160px;
    margin: 1rem auto;
}

.small-pic {
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

//----------------------------

.logo {
    max-width: 180px;
    margin: 0 2rem;
}

.app-title {
    text-transform: capitalize;
    font-size: var(--ion-font-body);
}

.table-cell {
    display: table-cell;
}

a {
    color: black;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
}

.footer-bg {
    background-color: #80808024;
}

.col-child {
    margin: .5rem auto;
    width: 180px;
    line-height: 2rem;
}

@mixin nbr {
    background-color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 700;
    color: var(--ion-orange);
    box-shadow: 2px 4px 5px #e0d9d9;
    border-radius: 50%;

    border: 1px solid var(--ion-orange);
}

.div-number {
    @include nbr;
    font-size: var(--ion-font-body-bigger);
    padding: 1rem;
    margin: -1.7rem auto 1.2rem auto;
}

.div-number-2 {
    @include nbr;
    font-size: var(--ion-font-body);
    margin: -4rem auto auto auto;
    z-index: 100;
    position: relative;
}

.mat-progress-bar-fill::after {
    background-color: var(--ion-orange) !important;
}

.underline {
    text-decoration: underline;
}

.err {
    color: var(--ion-red);
    font-size: var(--ion-font-body-smaller);
    margin-top: .4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
        font-size: var(--ion-font-body-smaller);
    }

    ion-icon {
        font-size: 1.13rem;
        margin-right: 5px;
    }

    div {
        border: 0;
        padding: 0;

        ion-icon {
            font-size: 1.13rem;
            margin-right: 5px;
        }
    }

}

.info {
    color: grey;
    font-size: var(--ion-font-body-smaller);
    margin-top: .2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
        font-size: var(--ion-font-body-smaller);
    }

    ion-icon {
        font-size: 1.13rem;
        margin-right: 5px;
    }

    div {
        border: 0;
        padding: 0;

        ion-icon {
            font-size: 1.13rem;
            margin-right: 5px;
        }
    }

}

.modal-wrapper.sc-ion-modal-md {
    max-width: 1200px;
    width: 84%;
    height: 96%;
}

:host {
    --min-height: 55px;
    --background: var(--ion-orange);
    --background-activated: : #ffd54c;
    --background-focused: : #ffd54c;
    --background-hover: var(--ion-orange);
    --background-activated-opacity: 0;
    --background-focused-opacity: 0.12;
    --background-hover-opacity: 0.04;
    --color: black;
    --highlight-color-focused: black;
    --padding-start: 0px;
    --inner-padding-end: 0px;
}

.my-modal-class {
    --max-width: 1100px;
    --width: 98% !important;
    --height: 96% !important;
}

//------------Swiper
.profile-swiper {
    .swiper {
        padding: 0 0 2rem 0 !important;
    }

    .swiper-slide {
        width: 250px !important;
    }
}

.profile-swiper-2 {
    .swiper {
        padding: 0 0 2rem 0 !important;
    }

    .swiper-slide {
        width: 160px !important;
    }
}

.profile-swiper-3 {
    .swiper {
        padding: 0 0 2rem 0 !important;
    }

    .swiper-slide {
        width: 400px !important;
    }
}


.custom-image-main img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.image {
    background-color: #e1e1e1;
}

.swiper-button-prev,
.swiper-button-next {
    background-color: white;
    height: 30px;
    border-radius: 30px;
}

.it {
    margin: 1.5rem;
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 1px solid grey;
    background-color: white;
    border-radius: 10px;
}

.preview {
    width: 100%;
}

.reviews-nbr {
    background-color: #ffffff;
    padding: 0 0.3rem;
    font-weight: 600;
    border-radius: 4px;
    font-size: var(--ion-font-body-small);
}

.top-profile-img {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    margin-left: 0.3rem;
    object-fit: cover;
}

.line-height1-6 {
    line-height: 1.6rem;
}

.line-height1 {
    line-height: 1rem;
}

.line-height2 {
    line-height: 3rem;
}

.section-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: var(--ion-font-body);
    font-weight: 600;
}

.top-camera {
    width: 50px;
    height: 50px;
    position: relative;
    background: white;
    border-radius: 50%;
    padding: 0.2rem;
    border: 1px solid grey;
    margin: 0 auto
}

.rom-font {
    font-family: 'Times New Roman', Times, serif
}

.cert {
    max-width: 300px;
    margin-top: 2rem;
    cursor: pointer;
    border: 1px solid rgb(145, 145, 145);
    border-radius: 5px;
}

.icon-img {
    height: 30px !important;
    margin-right: .5rem;
}

#autoComplete {
    position: relative;

    #autoCompleteList {
        box-shadow: 0px 0px 4px rgb(179, 179, 179);
        border-radius: 8px;
        position: absolute;
        background: #383838;
        z-index: 100;
        width: 100%;
        max-height: 225px;
        overflow: auto;
        color: white;

        .auto-complete-item {
            padding: 8px 24px;
            font-size: var(--ion-font-body);

            .line-2 {
                color: gray;
                font-size: var(--ion-font-body-small);
            }
        }
    }
}

.oran {
    color: var(--ion-orange);
}


// ------------ scroll 

.scroll {
    overflow-x: scroll !important;
    overflow-y: auto !important;
}

/* width */
.scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
    background-color: #eeeded;
    border-radius: 5px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: rgb(183, 195, 204);
    border-radius: 10px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(135, 185, 226);
}

.flex-rowcenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}


.flex-rowstart {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.flex-colcenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.top-left-meal {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 930px;
    margin: .5rem auto;
}

.order-calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--ion-font-body);
    font-weight: 600;

    :hover {
        color: #039c10
    }

    div {
        text-align: center;
        border-radius: 5px;
        min-width: 60px;
        height: 80px;
        border: 1px solid #e9e5e5;
        margin: 0.5rem 0.2rem;
        padding: 0.1rem;
        cursor: pointer;
        font-size: 14px;

        :hover {
            color: #039c10
        }

        &.cal-selected {
            background-color: var(--ion-orange);
        }
    }
}

.left-titles {
    width: 140px;
    display: flex;
    justify-content: left;
    border: unset !important;
    font-size: var(--ion-font-body);
    font-weight: 500;
    padding: 0 !important;
}

.sharp {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.order-calendar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--ion-font-body);
    font-weight: 600;


    div {
        text-align: center;
        border-radius: 5px;
        min-width: 200px;
        border: 1px solid #e9e5e5 !important;
        margin: 1rem 0.4rem 0 0.4rem;
        cursor: pointer;

        &.cal-selected {
            background-color: #fff0ad;
        }
    }
}

.div-dot {
    width: 13px !important;
    height: 13px !important;
    border-radius: 50% !important;
    background-color: #dedede;
    min-width: 13px !important;
    margin: auto !important;
    position: relative;
    bottom: -1.2rem;
    padding: unset !important;
}

.green-dot {
    background-color: var(--ion-green);
}


.div-dot-2 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--ion-green);
    margin: 0 1rem
}

.show-small-header {
    display: none;
}


#logo-mob {
    cursor: pointer;
    width: 260px;
}

.left-menu {
    max-width: 180px
}

.b-menu {
    font-size: var(--ion-font-body-big);
}

.os {
    font-family: "Oswald", sans-serif !important;
}

.os-bold {
    font-family: "Oswald-bold", sans-serif !important;
}

.f-10 {
    font-size: 10px !important;
}

.f-11 {
    font-size: 11px !important;
}

.f-12 {
    font-size: 12px !important;
}

.f-13 {
    font-size: 13px !important;
}

.f-14 {
    font-size: 14px !important;
}

.f-15 {
    font-size: 15px !important;
}

.f-16 {
    font-size: 16px !important;
}

.f-17 {
    font-size: 16px !important;
}

.f-18 {
    font-size: 18px !important;
}

.f-19 {
    font-size: 19px !important;
}

.f-20 {
    font-size: 20px !important;
}

.f-21 {
    font-size: 21px !important;
}

.f-22 {
    font-size: 22px !important;
}

.f-23 {
    font-size: 23px !important;
}

.f-24 {
    font-size: 24px !important;
}

.f-33 {
    font-size: 33px !important;
}

.f-40 {
    font-size: 40px !important;
}

.f-g {
    font-size: 1rem;
}



.ion-spinners {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    color: black;
}

.label-stacked.sc-ion-label-md-h,
.label-floating.sc-ion-label-md-h {
    margin-bottom: 8px !important;
}

.native-input.sc-ion-input-md {
    padding: 5px 5px 5px 10px !important;
}

@mixin tg {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    hr {
        height: 4px;
        border: 0;
        border-radius: 5px;
        background-color: var(--ion-orange);
        width: 40px;
    }
}

.title-g {
    align-items: flex-start;
    @include tg;
}

.title-g-center {
    align-items: center;
    @include tg;
}

.col-box {
    padding: 0.5rem 1rem;
    background-color: white;
    min-width: 300px;
    border-radius: 7px;
    box-shadow: 1px 1px 5px #c0c0c0;
    max-width: 100%;
    margin: 1rem auto 2rem auto;
}

.mobile-box {
    padding: 0.5rem 1rem 1.5rem;
    background-color: white;
    min-width: 300px;
    border-radius: 7px;
    box-shadow: 1px 1px 5px #c0c0c0;
}


.f-smaller {
    font-size: var(--ion-font-body-smaller);
}

.f-small {
    font-size: var(--ion-font-body-small);
}

.f-regular {
    font-size: var(--ion-font-body) !important;
}

.f-regular-2 {
    font-size: var(--ion-font-body-2);
}

.f-big {
    font-size: var(--ion-font-body-big);
}

.f-bigger {
    font-size: var(--ion-font-body-bigger);
}



.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

//////////////////////////////--------------------------------------

//-------------------------flex-------------------------------------

.flex {
    display: flex;
}

.f-row {
    flex-direction: row;
}

.f-col {
    flex-direction: column;
}

.f-just-center {
    justify-content: center;
}

.f-just-start {
    justify-content: flex-start;
}

.f-just-end {
    justify-content: flex-end;
}

.f-just-btw {
    justify-content: space-between;
}

.f-just-around {
    justify-content: space-around;
}

.f-just-evenly {
    justify-content: space-evenly;
}

.f-align-center {
    align-items: center;
}

.f-align-start {
    align-items: flex-start;
}

.f-align-end {
    align-items: flex-end;
}

.f-align-base {
    align-items: baseline;
}

.f-wrap {
    flex-wrap: wrap;
}

.f-nowrap {
    flex-wrap: nowrap;
}

//-----------------------------------fonts----------------------------

.f0-6 {
    font-size: 0.6rem;
}

.f0-65 {
    font-size: 0.65rem;
}

.f0-7 {
    font-size: 0.7rem;
}

.f0-75 {
    font-size: 0.75rem;
}

.f0-8 {
    font-size: 0.8rem !important;
}

.f0-9 {
    font-size: 0.9rem !important;
}

.f0-95 {
    font-size: 0.95rem !important;
}

.f1-0 {
    font-size: 1rem;
}

.f1-05 {
    font-size: 1.05rem;
}

.f1-1 {
    font-size: 1.1rem;
}

.f1-2 {
    font-size: 1.2rem;
}

.f1-3 {
    font-size: 1.3rem;
}

.f1-4 {
    font-size: 1.4rem;
}

.f1-5 {
    font-size: 1.5rem;
}

.f1-6 {
    font-size: 1.6rem;
}

.f1-7 {
    font-size: 1.7rem;
}

.f1-8 {
    font-size: 1.8rem;
}

.f1-9 {
    font-size: 1.9rem;
}

.f2-0 {
    font-size: 2rem !important;
}

.f2-5 {
    font-size: 2.5rem !important;
}

.f3-0 {
    font-size: 3rem !important;
}

.f3-5 {
    font-size: 3.5rem !important;
}

.f4-0 {
    font-size: 4rem !important;
}


//-------------------------margin-top------------
.margin-top-03 {
    margin-top: 0.3rem !important;
}

.margin-top-04 {
    margin-top: 0.4rem !important;
}

.margin-top-05 {
    margin-top: 0.5rem !important;
}

.margin-top-1 {
    margin-top: 1rem;
}

.margin-top-1-5 {
    margin-top: 1.5rem;
}

.margin-top-2 {
    margin-top: 2rem;
}

.margin-top-2-5 {
    margin-top: 2.5rem;
}

.margin-top-3 {
    margin-top: 3rem;
}

.margin-top-4 {
    margin-top: 4rem;
}

.margin-top-5 {
    margin-top: 5rem;
}

.margin-top-8 {
    margin-top: 8rem;
}

//------------------------margin-left-------------

.margin-left-03 {
    margin-left: 0.3rem;
}

.margin-left-05 {
    margin-left: 0.5rem;
}

.margin-left-1 {
    margin-left: 1rem;
}

.margin-left-15 {
    margin-left: 1.5rem;
}

.margin-left-2 {
    margin-left: 2rem;
}

.margin-left-3 {
    margin-left: 3rem;
}

.margin-left-4 {
    margin-left: 4rem;
}

//------------------------margin-right-------------

.margin-right-05 {
    margin-right: 0.5rem;
}

.margin-right-1 {
    margin-right: 1rem;
}

.margin-right-1-5 {
    margin-right: 1.5rem;
}

.margin-right-2 {
    margin-right: 2rem;
}

//-------------------------margin-bottom--------------
.margin-bottom-05 {
    margin-bottom: .5rem;
}

.margin-bottom-1 {
    margin-bottom: 1rem;
}

.margin-bottom-2 {
    margin-bottom: 2rem;
}

.margin-bottom-3 {
    margin-bottom: 3rem;
}

.margin-bottom-6 {
    padding-bottom: 6rem;
}

//------------------------padding------------
.padding-05 {
    padding: .5rem;
}

.padding-1 {
    padding: 1rem;
}

.padding-2 {
    padding: 2rem;
}

ion-accordion {
    background-color: white;
}

//------------------------colors---------------
.pri-col {
    color: var(--pri-col)
}

.sec-col {
    color: var(--sec-col)
}

.pri-col-bg {
    background-color: var(--pri-col)
}

.sec-col-bg {
    background-color: var(--sec-col)
}

.white {
    color: white !important;
}

.black {
    color: black !important;
}

.red {
    color: #ff0000 !important;
}

.grey {
    color: grey !important;
}

.green {
    color: green !important;
}

.grey-light {
    color: #8c8b8b !important;
}

.grey-light-2 {
    color: #b7b7b7 !important;
}

.white-bg {
    background-color: white !important;
}

//----------------------------others

.bold-0 {
    font-weight: 0;
}

.bold-500 {
    font-weight: 500;
}

.bold-600 {
    font-weight: 600;
}

.bold-no {
    font-weight: unset;
}

.border-red {
    border: 1px solid red;
}

.border-bot {
    border-bottom: 1px solid #e5e4ff;
}

.border-top {
    border-top: 1px solid #e5e4ff;
}

.border-grey {
    border: 2px solid #c6c6c6;
}

.border-radius-50 {
    border-radius: 50%;
}

.border-radius {
    border-radius: 7px;
}

.border-radius-2 {
    border-radius: 20px;
}

.shadow {
    box-shadow: 2px 2px 5px #dbdbdb;
}

.orange-bg {
    background-color: #fffbf5;
}

.grey-bg {
    background-color: #f8f8f8;
}

.yellow-bg {
    background-color: #ffffc2;
}

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

//-----------------------

.orange-dark {
    color: var(--ion-col1) !important;
}

.orange-med {
    color: var(--ion-col2) !important;
}

.orange-light {
    color: var(--ion-col3) !important;
}

/////////////////////////-------------------- 

.templ-container {
    padding: 1rem;

    div {
        max-width: 1200px;
        margin: 0 auto;
        width: 98%;
    }
}

.poppins-thin {
    font-family: 'poppins-thin';
}


.poppins {
    font-family: 'poppins';
}

.poppins-bold {
    font-family: 'poppins-bold';
}

.poppins-med {
    font-family: 'poppins-med';
}

.svg {
    height: 16px;
}

div,
ion-col {
    font-family: 'poppins';
    font-size: 1rem;
}

span {
    font-size: .95rem;
}

select {
    background-color: #fcfcfc;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d5d4d4;
    padding: 0.2rem;
    margin-top: 0.3rem;
    max-width: 350px;
}

.relative {
    position: relative;
}

.required {
    color: var(--ion-col1);
    font-size: .9rem;
    font-family: 'poppins-bold';
}

ion-checkbox {
    margin-right: 1rem;
}

.auto {
    margin-left: auto;
    margin-right: auto;
}

.go-back {
    font-family: "poppins-med";
    font-size: 1.1rem;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

@media (max-width: 991px) {

    div,
    ion-col {
        font-family: 'poppins';
        font-size: .9rem;
    }

    .f-g {
        font-size: .8rem !important;
    }

    .col-box {
        box-shadow: unset;
        margin: 1rem 0;
        padding: .4rem;
    }

    ion-content {
        //  --background: #ffffff !important;
    }

    .show-big-header {
        display: none;
    }

    .left-menu {
        max-width: unset;
    }

    .show-small-header {
        display: block;
    }

    .page-size {
        margin: 1.5rem auto 0 auto !important;
    }

    .f-smaller {
        font-size: var(--ion-font-body-smaller);
    }

    .f-small {
        font-size: var(--ion-font-body-small);
    }

    .f-regular {
        font-size: var(--ion-font-mobile-body) !important;
    }

    .f-regular-2 {
        font-size: var(--ion-font-mobile-body-2);
    }

    .f-big {
        font-size: var(--ion-font-mobile-body-big);
    }

    .f-bigger {
        font-size: var(--ion-font-mobile-body-bigger);
    }

    .alert {
        font-size: var(--ion-font-body-smaller);
    }
}

ion-label {
    font-family: 'poppins';
    font-size: .9rem;
}

.standard-btn {
    max-width: 350px;
    width: 100%;
    font-family: "poppins-bold" !important;
}

ion-loading.custom-loading {
    --background: #e3edff;
    --spinner-color: var(--ion-col1);
    --width: 500px;
    --height: 200px
}

.max-width-680 {
    max-width: 680px;
}

.max-width-800 {
    max-width: 800px;
}

.my-input {
    margin-top: 1.2rem;

    label {
        font-family: "poppins";
        font-size: 16px;
        color: black;
    }

    input[type=checkbox] {
        accent-color: var(--ion-col1);
        width: 16px;
        height: 16px;
        margin-right: 10px;
        cursor: pointer;
    }

    input {
        width: 100%;
        padding: 9px;
        margin: 2px 0;
        box-sizing: border-box;
        border: 1px solid #e5dede;
        transition: 0.4s;
        outline: none;
        border-radius: 5px;
        font-family: "poppins";
        font-size: 16px;
        color: black !important;
        background-color: white !important;
    }

    input:focus,
    input:hover {
        box-shadow: 2px 2px 6px #cfcfcf;
    }
}

.my-input-checkbox {

    label {
        font-family: "poppins";
        font-size: 16px;
        color: black;
    }

    input[type=checkbox] {
        accent-color: var(--ion-col1);
        width: 16px;
        height: 16px;
        margin-right: 10px;
        cursor: pointer;
    }

    input {
        width: 100%;
        padding: 9px;
        margin: 2px 0;
        box-sizing: border-box;
        border: 1px solid #e5dede;
        transition: 0.4s;
        outline: none;
        border-radius: 5px;
        font-family: "poppins";
        font-size: 16px;
        color: black !important;
        background-color: white !important;
    }

    input:focus,
    input:hover {
        box-shadow: 2px 2px 6px #cfcfcf;
    }
}

ion-button {
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 16px;
    font-family: 'poppins-med';
    max-width: 300px;
    height: 42px;
    --box-shadow: unset;
    margin: 0 auto;
}

.terms {
    font-size: 12px !important;
}

.classic-btn {
    background-color: #fef0c1;
    border: 1px solid #cacaca;
    border-radius: 6px;
    cursor: pointer;
    font-size: .8rem;
    padding: .3rem 1rem;
    color: black;
    font-family: 'poppins';

}

.classic-btn-white {
    background-color: #ffffff;
    border: 1px solid #aaaaaa;
    border-radius: 6px;
    cursor: pointer;
    font-size: .8rem;
    padding: .3rem 1rem;
    color: black;
    font-family: 'poppins';

}

@media (max-width: 991) {
    ion-button {
        font-size: 14px;

    }

    .my-input {
        label {
            font-size: 14px;
        }

        input {
            font-size: 14px;
            padding: 7px;
        }

    }
}

.template-container {
    max-width: 1400px;
    width: 100%;
    margin: 2rem auto;
    padding: 1rem;
}

.spin {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3A86F9;
    border-radius: 50%;
    background-color: #e6f0ff;
    margin-left: auto;
    margin-right: auto;

    ion-spinner {
        font-size: 3rem;
        color: #3A86F9
    }
}